import Contract from 'web3-eth-contract'
import onion from '../abi/onionAuction.json'
import  erc20 from '../abi/exs.json'
import utils from './utils'
import Web3 from 'web3'

const Auction = {
    contract(Provider, addressContract, abi) {
        const contract = new Contract(abi, addressContract)
        contract.setProvider(Provider.provider)
        return contract
    },
    socketContract(Provider, contractInfo) {
        const contract = new Contract(onion['abi'], contractInfo.address)
        contract.setProvider(Provider.sockets[0])
        return contract
    },
    /**
     * 
     * @param {Number} value Value Base
     * @param {Object} token token data to obtain decimals
     * @returns {Number} value converted to JS decimals
     */
    toDecimals(value, token) {
        const decimal = Math.pow(10, token.decimals) // {}*10**token.decimals
        const total = Number.parseFloat(value / decimal).toFixed(16) //please do not move this, as it maintains floating point precision.
        return total
    },
    /**
     * 
     * @param {Number} x Value Base
     * @param {Object} token token data to obtain decimals
     * @returns {Number} X converted to type solidity decimals
     */
    fromDecimals(x, token) {
        if (token.decimals == 18) {
            return utils.toWei(x)
        }
        const xInt = Math.pow(10, token.decimals)
        const xWei = x * xInt
        return xWei
    },

    async providerRPC(Provider, tokenAddress, abi) {
        try {
            const contract = this.contract(Provider, tokenAddress, abi)
            return contract
        } catch (error) {
            console.log(error)
        }

    },
    /**
     * 
     * @param {String} addressContract address of the contract onionAcution
     * @param {Json} abi Interface Solidity Contract
     * @returns {Object} Contract call Object
     */
    mobileProvider(addressContract, abi) {
        const contract = new Contract(abi, addressContract)
        contract.setProvider(`https://nodes.ex-sports.io/`)
        return contract
    },
    /**
     * Format from Solidity types to JS types
     * @param {Object} auction auction data with solidity types of the auctions
     * @param {Object} tokens cosntant to get data token
     * @returns {Object} a formated Object with JS types
     */
    formatAuctionData(auction, tokens) {
        //convert type bytes32 to {String}, tokename of the auction is made(USDT, EXS, BUSC)
        const currency = utils.fromBytes32(auction._currency)
        const token = tokens.find((x) => x.name == currency.toUpperCase())
        const pricePerCard = this.toDecimals(auction._pricePerCard, token)
        const incrementer = this.toDecimals(auction._incrementer, token)
        const auctionFormatted = {
            auctioner: auction._owner,
            tokenId: parseInt(auction._cardId),
            currency: currency,
            amount: auction._amountOnSale,
            pricePerCard: parseFloat(pricePerCard).toFixed(2),
            incrementer: parseFloat(incrementer).toFixed(2)
        }
        return auctionFormatted
    },
    /**
     * 
     * @param {Object} contractInfo Constant data of the onionAuction
     * @param {Object} tokens cosntant to get data token
     * @returns {Array} Array formated of each auction avalaible.
     */
    async onionAuctions(contractInfo, tokens) {
        try {
            const auctions = []
            const contract = await this.mobileProvider(contractInfo.address, onion['abi'])
                // return array with the auctionsID uint256[1,2,344,5]
            const batchAuctions = await contract.methods.getAllOnionsId().call()
                // return a solidity struct => js Object (_owner _athleteLoyalty _currency _cardId _amountOnSale _pricePerCard _incrementer;
            const auctionsDetails = await contract.methods.batchDetailsOnions(batchAuctions).call()
            auctionsDetails.forEach((auction, index) => {
                let formated = this.formatAuctionData(auction, tokens)
                formated.onionId = batchAuctions[index]
                auctions.push(formated)
            })
            return auctions
        } catch (error) {
            console.log(error)
        }


    },


    formatAuctionUpdated(auctionData, tokens) {
        const currency = utils.fromBytes32(auctionData[2])
        const token = tokens.find((x) => x.name == currency.toUpperCase())
        const pricePerCard = this.toDecimals(auctionData[4], token)
        const incrementer = this.toDecimals(auctionData[5], token)
        return {
            amount: auctionData[3],
            pricePerCard: parseFloat(pricePerCard).toFixed(2),
            incrementer: parseFloat(incrementer).toFixed(2)
        }
    },
    async auction(onion, auction, tokens) {
        const auctionData = await onion.methods.onionAuctionDetails(auction.onionId).call()
        var formated = this.formatAuctionUpdated(auctionData, tokens)
        return formated
    },
    /**
     * 
     * @param {Object} Provider wallet provider
     * @param {String} address user Address
     * @param {Object} auction Auction to buy
     * @param {Object} onionContract constant Data of the Onion contract
     * @param {Object} tokens cosntant to get data token
     * @param {Object} dispatch 
     * @param {Object} popup state from 'notificationSystem' module
     * @returns {Boolean} if the transactions have been sent correctly, it returns true, 
     * but this does not mean that the purchase has been successful, 
     * since it is necessary to wait for the miners to validate the tx,
     *  and successfully add the tx's to the block.
     * you must have to weigh that the transaction appears as sent 
     * but in the blockchain it is reversed or sent and validated correctly.
     */
    async buyAuction(Provider, address, auction, onionContract, tokens, dispatch, popup) {
        try {
            // find the address contract using the name as reference
            var erc20Contract = tokens.find((x) => x.name == auction.currency.toUpperCase())
            var gasPrice = await Provider.provider.request({ method: 'eth_gasPrice', params: [] })
            gasPrice = Web3.utils.hexToNumberString(gasPrice)
                // Contract calls to onionAuctions.sol
            const contractAuction = await this.providerRPC(Provider, onionContract.address, onion['abi'])
                //Contract calls to ERC20.sol
            const ERC20Contract = await this.providerRPC(Provider, erc20Contract.address, erc20['abi'])
            var actualAuction = await this.auction(contractAuction, auction, tokens)
            if (actualAuction.pricePerCard <= auction.pricePerCard) { // avoid race condition in the blockchain
                var amount = this.fromDecimals(auction.pricePerCard, erc20Contract)
                const gas = await ERC20Contract.methods.approve(onionContract.address, amount).estimateGas({ from: address })
                    //allows the auction contract to manage user address funds.
                const allowed = await ERC20Contract.methods.allowance(address, onionContract.address).call()
                if (allowed < amount) {
                    popup.allowance = true //shows the user that he/she needs to approve funds to be spent on the contract.
                    await dispatch('notificationSystem/add', popup, { root: true })
                    await ERC20Contract.methods.approve(onionContract.address, amount).send({
                        from: address,
                        gas: gas,
                        gasPrice: parseInt(gasPrice) * 4
                    }).on('transactionHash', async function(hash) {
                        var update = { //if the transaction is sent add tx to popup this does not mean that the transaction has been processed successfully.
                            type: "buyCard",
                            key: "approveTx",
                            value: hash
                        }
                        await dispatch('notificationSystem/update', update, { root: true })
                        await contractAuction.methods.buyCard(auction.onionId, address).send({
                            from: address,
                            gas: gas * 10,
                            gasPrice: parseInt(gasPrice) * 4
                        }).on('transactionHash', async function(hash) {
                            var update = {
                                type: "buyCard",
                                key: "purchaseTx",
                                value: hash
                            }
                            await dispatch('notificationSystem/update', update, { root: true })
                            return true
                        })
                        await dispatch('Wallet/updateBalancesERC1155', null, { root: true })
                    })
                } else {
                    await dispatch('notificationSystem/add', popup, { root: true })
                    await contractAuction.methods.buyCard(auction.onionId, address).send({
                        from: address,
                        gas: gas * 10,
                        gasPrice: parseInt(gasPrice) * 4
                    }).on('transactionHash', async function(hash) {
                        var update = {
                            type: "buyCard",
                            key: "purchaseTx",
                            value: hash
                        }
                        await dispatch('notificationSystem/update', update, { root: true })
                        return true
                    })
                    await dispatch('Wallet/updateBalancesERC1155', null, { root: true })
                }
            } else {
                //if there is a race condition in the blockchain states warns the user that the auction data has changed.
                auction.pricePerCard = actualAuction.pricePerCard
                auction.nextPrice = actualAuction.nextPrice
                var data = {
                    active: true,
                    auction: auction
                }
                dispatch("notificationSystem/addNewPrice", data, { root: true })
                dispatch("Wallet/updateAuctions", null, { root: true })
            }
        } catch (error) {
            console.log(error)
        }
    },

    callBack(err, result) {
        console.log(err, result)
        return true
    }

}

export default Auction