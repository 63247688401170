import React,{useState} from 'react'
import Banner from '../Banner/index';
import HotCollection from '../HotCollection/index'
import TVSupport from '../TvSupport/index'
import SearchNFT from '../SearchNFTS/index'
import MarketPlace from '../marketplace/marketplace'
function Home() {
    const [searchResult,setSearchResult]  = useState(false)
    return (
        <>  {searchResult == true ? <SearchNFT/> :
            <> 
            <Banner />
            <HotCollection />
            <MarketPlace home={true} lower = {0} higher = {8}/>
            <TVSupport />
            </>
        }
        </>
    )
}

export default Home
