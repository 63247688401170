import React from 'react'
import { Select } from 'antd';
import './styles.css'
import {Link} from 'react-router-dom'
import { paymentOptions } from '../../configurations/paymentOptions'
function Header(props) {
    let loggedUser = JSON.parse(localStorage.getItem("exsportLogin"))
    const { Option } = Select;
    return (
        <header class="header-section">
            <div class="overlay">
                <div class="container">
                    <div class="row d-flex header-area align-items-center">
                        <nav class="navbar navbar-expand-lg navbar-light p-0 align-items-center">
                            <Link class="navbar-brand" to="/">
                                <img src="../../assets/images/ex-sports-logo.png" class="logo" alt="logo" />
                            </Link>
                            <div class="nav-serch-bar ms-3 desktop-search">
                        <input type="text" class="form-control search-input" placeholder = "search" onChange={(e)=>{
                            props?.searchFiled(e?.target?.value)
                        }}/>
                        {/* <div class = "search-dropdown">
                             <ul class="nav flex-column">
                                <li class="nav-item px-3 py-2">
                                    <div class="search-item d-flex align-items-center">
                                        <img src="assets/images/search-card.png" alt="search-list-img"/>
                                        <span>Adaylton Freitas - NFT</span>
                                    </div>
                                </li>
                                <li class="nav-item px-3 py-2">
                                    <div class="search-item d-flex align-items-center">
                                        <img src="assets/images/search-card.png" alt="search-list-img"/>
                                        <span>Upcoming match - Adaylton vs ...</span>
                                    </div>
                                </li>
                            </ul> 
                        </div> */}
                    </div>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#ex-navbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fas fa-bars"></i>
                            </button>
                            <div class="collapse navbar-collapse" id="ex-navbar">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li class="nav-item nav-dropdown">
                                        <a href="#" class="nav-link nav-dropdown-link">
                                            Marketplace
                                        </a>
                                        <ul class="nav-column">
                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    Soccer
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    Muaythai
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    Jujitsu
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    Fitness
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            My Album
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Rewards
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            EX Sports TV
                                        </a>
                                    </li>
                                    <div class="btn-sec-navbar d-lg-none mt-4">
                                        <div class="row w-100">
                                            <div class="col">
                                                <div class="f-group">
                                                    <Select placeholder="Payment Option"

                                                        style={{ width: 150 }}
                                                        className="paymentOptions"
                                                        optionFilterProp="children"
                                                        onChange={() => { }}
                                                    >
                                                        <Option value="1"> <img src="assets/images/icons/eh.svg"></img>  Ethereum</Option>
                                                        <Option value="2"> <img src="assets/images/icons/polygon.svg"></img>  Polygon</Option>

                                                    </Select>
                                                </div>
                                            </div>
                                            {loggedUser == null ? <div class="col">
                                                <a href="/login" class="btn btnm-primary login-btn">
                                                    <img src="assets/images/icons/login.svg" alt="btn-icon" className='header-login-image' />
                                                    Login
                                                </a>
                                            </div> : <div class="col">
                                                <><a href="/connect-wallet" class="btn btnm-primary ">
                                                    <img src="../../assets/images/icons/wallet-ic.svg" alt="btn-icon" />
                                                    Connect Wallet
                                                </a>
                                                    <div class="dropdown">
                                                        <button class="share-btn dropdown-toggle" id="sharedd" data-bs-toggle="dropdown">
                                                            MY Profile
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="sharedd">
                                                            <li>
                                                                <a href="#" class="dropdown-item">
                                                                    <i class="fas fa-link me-1"></i> Copy link
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#select-cardModal">
                                                                    <i class="fab fa-facebook me-1"></i> Share on Facebook
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" class="dropdown-item">
                                                                    <i class="fab fa-twitter me-1"></i>  Share on Twitter
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" class="dropdown-item">
                                                                    <i class="fas fa-code me-1"></i> Embed Item
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </>
                                            </div>}
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div class="btn-sec-navbar btn-sec-desktop">
                                <div class="f-group">
                                    {/* <select class="f-control f-dropdown" placeholder="Select">
                                        <option value=""> </option>
                                        <option value="1" data-image="https://source.unsplash.com/50x50/?nature">Ethereum</option>
                                        <option value="2" data-image="https://source.unsplash.com/50x50/?water">Bitcoin</option>
                                        <option value="3" data-image="https://source.unsplash.com/50x50/?spring">Fb coin</option>
                                        <option value="4" data-image="https://source.unsplash.com/50x50/?sea">pak coin</option>
                                        <option value="5" data-image="https://source.unsplash.com/50x50/?moon">coin</option>
                                    </select> */}
                                    <Select placeholder="Payment Option"

                                        style={{ width: 150 }}
                                        className="paymentOptions"
                                        optionFilterProp="children"
                                        onChange={() => { }}
                                    >
                                        <Option value="1"> <img src="assets/images/icons/eh.svg"></img>  Ethereum</Option>
                                        <Option value="2"> <img src="assets/images/icons/polygon.svg"></img>  Polygon</Option>

                                    </Select>
                                </div>
                                {loggedUser == null ? <a href="/login" class="btn btnm-primary login-btn">
                                    <img src="assets/images/icons/login.svg" alt="btn-icon" className='header-login-image' />
                                    Login
                                </a> : <><a href="/connect-wallet" class="btn btnm-primary ">
                                    <img src="../../assets/images/icons/wallet-ic.svg" alt="btn-icon" />
                                    Connect Wallet
                                </a>
                                    <div class="dropdown myProfile">
                                        <button class="share-btn dropdown-toggle" id="sharedd" data-bs-toggle="dropdown">
                                            My Profile
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="sharedd">
                                            <li>
                                                <a href="#" class="dropdown-item">
                                                    <img src='assets/images/icons/Vector (1).svg' /> My Profile
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#select-cardModal">
                                                    <img src='assets/images/icons/mywallet.svg' /> My Wallet
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="dropdown-item">
                                                    <img src='assets/images/icons/reward.svg' />  My Reward
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="dropdown-item">
                                                    <img src='assets/images/icons/signout.svg' /> Sign Out
                                                </a>
                                            </li>
                                        </ul>
                                    </div></>}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
