
import React from 'react'
import Web3 from 'web3';
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { formatEther } from '@ethersproject/units'
import {Link} from 'react-router-dom'

import { useEagerConnect, useInactiveListener } from './hooks'
import { Send } from './send'
import { Sign } from './sign'

import {
    injected,
    mewConnect
} from './connectors'

const ConnectorNames = {
    Injected: 'DappBrowser',
    MEWconnect: 'MewConnect'
}

const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.MEWconnect]: mewConnect
}

function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

export default function () {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <ConnectWallet />
        </Web3ReactProvider>
    )
}

function ConnectWallet() {

    const walletConnection = () => {

        const web3 = new Web3('https://ropsten.infura.io/v3/ec38a0cbd646447d89959f632d9829fd')
        if (window.ethereum) {

            // res[0] for fetching a first wallet
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((res) => {
                    console.log(res[0])
                });
        } else {
            alert("install metamask extension!!");
        }
    }
    const context = useWeb3React()
    const { connector, library, chainId, account, activate, deactivate, active, error } = context

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = React.useState()
    React.useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined)
        }
    }, [activatingConnector, connector])

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect()

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector)
    return (<section class="login-signup-form bg-main">
        <div class="overlay py-5">
            <div class="container py-3 py-lg-4">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6">
                        <div class="form-section connect-wallet">
                            <h1>
                                Connect Wallet
                            </h1>

                            <div class="form-group">
                                <p>
                                    By connecting a wallet, you agree to ExSports Terms of Service
                                    and acknowledge that you have read and understand<br />
                                    <a href="#">The Ex-Sports Terms and Conditions</a>
                                </p>
                            </div>

                            <div class="form-group">
                                <a href="#" class="btn btn-light-outline w-100 text-start" onClick={(e) => {
                                    e.preventDefault()
                                    walletConnection()

                                }}>
                                    <img src="assets/images/icons/metamask.svg" alt="" /> Metamask
                                </a>
                            </div>

                            {/* <div class="form-group">
                                <a href="#" class="btn btn-light-outline w-100 text-start">
                                    <img src="assets/images/icons/WalletConnect.svg" alt="" /> Wallet Connect
                                </a>
                            </div> */}
                            <div class="form-group">
                                <a href="#" class="btn btn-light-outline w-100 text-start" onClick={(e) => {
                                    e.preventDefault()
                                    // setActivatingConnector(currentConnector)
                                    activate(connectorsByName['MewConnect'])
                                    console.log(context)
                                }}>
                                    <img src="assets/images/icons/Exs.svg" alt="" /> Create EXS Wallet
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col text-center">
                        <p>
                            <Link to = "/marketplace" class="text-light">Skip this step</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

