import {
    useNavigate
} from "react-router";

export const useNavigator = () => {
   let loggedUser = JSON.parse(localStorage.getItem("exsportLogin"))
   const naviagte = useNavigate()
   const navigator = (path = '/') => {
       if (path == '/') {
           naviagte('/')
       }
       else {
           if(loggedUser == null){
               naviagte('/login')
           }
           else{

           naviagte(path)
       }
       }
   }

   return [navigator]

}
