import axios from 'axios';
// import { message } from "antd";

export const TIMEOUT = 15000;
const instance = axios.create({
  baseURL: "https://marketplace.ex-sports.io/metadata/api/v1",
  timeout: TIMEOUT
});
instance.interceptors.response.use(response=>response,(error)=>{
  if(error.response.status == 401){
    // message.error('User session expired',2)
    // localStorage.removeItem("loggedUser")
    // window.location = '/login'
  }
  return error
})
export default instance;
