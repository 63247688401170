import axios from '../../../axiosConfig/exaxios'
export const nfts = {
    getNFT:(callback)=>{
        axios.get('/athletes',
        {
            headers:{
                'Content-Type': 'application/json',
            }
        }
        )
        .then((data)=>{
         console.log(data)
         callback(null,data)
        }).catch(err=>{
            console.log(err)
            callback(err,null)
        })
    }
}