import React, { useState } from 'react'
import ForgetPassword from './ForgetPassword'
import ReCAPTCHA from "react-google-recaptcha";
import { commonFunction } from '../../utitlities/utility'
import {useNavigator} from '../../utitlities/navigation'
import { Link } from "react-router-dom";
import { firebase } from '../../firebaseconfig/firebaseconfig';
import './styles.css'
import { user } from '../../api/user'
function Login() {
    const [navigator] = useNavigator();
    const [forgetPassword, setForgetPassword] = useState(false)
    const [email, setEmail] = useState(false)
    const [password, sePassword] = useState(false)
    const [error,setError] = useState("")
    const [showError,setShowError] = useState(false)

    const reset = (id)=>{
           user.forgetPassword(id,(err,data)=>{
               console.log(data)
           })
    }
    const handleLoginFacebookFormSubmit=()=> {
        const provider = new firebase.auth.FacebookAuthProvider();
        signUp(provider)
    },
    handleLoginGoogleFormSubmit=()=> {
        const provider = new firebase.auth.GoogleAuthProvider();
        signUp(provider)
    }
    const signUp = async(provider)=> {
        firebase.auth().languageCode = 'en';
        try {
            const result = await firebase.auth().signInWithPopup(provider)
            // if(this.mainAddress === "") {
            //     this.errorMessage('Not wallet')
            //     return false
            // }
            const credential = {
                idToken: await result.user.getIdToken(),
                // address: this.mainAddress
            }
            // this.socialLogin(credential)
            console.log(credential);
            user.socialLogin(credential,(err,data)=>{
                console.log(data)
            })

        } catch (error) {
            console.log(error)
        }
    }
    const login = async () => {
        const email = document.getElementById("loginEmail")?.value
        const password = document.getElementById("loginPassword")?.value
        if (commonFunction.onlySpaces(email) == true) {
            setEmail(true)
            setError("Enter a valid Email")
            setShowError(true)
            setTimeout(() => {
                setError("")
                setShowError(false)
            }, 5000);
            return
        }
        else {
            setEmail(false)
        }
        if (commonFunction.checkPassword(password) == true) {
            sePassword(true)
            return
        }
        else {
            sePassword(false)
        }

        user.login({
            email: email,
            password: password,

        }, (err, data) => {
            console.log(data);
            if(data != null){
                if('token' in data?.data){
                    setError("You are successfully login")
                    setShowError(true)
                    setTimeout(() => {
                        setError("")
                        setShowError(false)
                    }, 5000);
                    localStorage.setItem("exsportLogin",JSON.stringify(data?.data))
                    navigator('/marketplace')
                }
                else{
                    setError(data?.data?.error?.verification?.[0])
                    setShowError(true)
                    setTimeout(() => {
                        setError("")
                        setShowError(false)
                    }, 4000);
                }
            }
            else{
                setError("Something Went Wrong")
                setShowError(true)
                setTimeout(() => {
                    setError("")
                    setShowError(false)
                }, 5000);
            }
        })

    }
    return (forgetPassword == false ?
        <section class="login-signup-form bg-main">
            <div class="overlay py-5">
                <div class="container py-3 py-lg-4">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <div class="form-section">
                                <h1>
                                    Login
                                </h1>
                                {showError == true ? <p style={{color:"red",fontSize:'15px'}}>{error}</p>:null}
                                <div class="form-group">
                                    <label for="Email">Email</label>
                                    <input type="text" class={`form-control ${email ? 'error-color' : ''}`} id="loginEmail" />
                                </div>

                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <span class="forg-link">
                                        <Link to='/forgetPassword' >Forgot Password?</Link>
                                    </span>
                                    <input type="password" class={`form-control ${password ? 'error-color' : ''}`} id="loginPassword" />
                                </div>
                                {/* <div class="form-group">
                                    <ReCAPTCHA
                                        sitekey="Your client site key"
                                        onChange={()=>{}}
                                    />
                                </div> */}

                                <div class="form-group">
                                    <input type="submit" value="Log in" class="form-control btnm-primary text-uppercase" onClick={() => {
                                        login()
                                    }} />
                                </div>

                                <div class="form-group">
                                    <div class="or-sec py-2">
                                        <span>OR</span>
                                    </div>
                                </div>

                                <div class="form-group" style={{textAlign:"center"}}>

                                    <button className='socialIcon'  onClick={()=>{
                                        handleLoginGoogleFormSubmit()
                                    }}>
                                    <img class="" src="assets/images/icons/google.svg" alt="" className='logo1'/>
                                </button>
                                <button href="#" className='socialIcon' onClick={()=>{
                                    handleLoginFacebookFormSubmit()
                                }}>
                                <img src="assets/images/icons/fb1.svg" alt="" className='logo1'/>
                            </button>
                            <a href="#" class="filter grayscale">
                                <img src="../assets/img/ellipse_3.png" alt="" />
                            </a>
                        </div>

                        <div class="form-group">
                            <p class="text-center">
                                Don't have an account? <a href="/signup">Sign up</a>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
            </div >
        </section > : <ForgetPassword reset ={reset} />
    )
}

export default Login
