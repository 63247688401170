import React,{useState} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from '../Header/index'
import Footer from '../Footer/index'
import Home from '../Home/index';
import Login from '../Login/index';
import Signup from '../Signup/index';
import ForgetPassword from '../Login/ForgetPassword';
import ConnectWallet from '../ConnectWallet/index'
import Cards from '../marketplace/index'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import SearchNFTS from '../SearchNFTS/index'
function Router(props) {
  const [search,setSearch] = useState(false)
  function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }
  const searchFiled = (value)=>{
          if(value?.trim() == ""){
            setSearch(false)
          }
          else{
            setSearch(true)
          }
  }
  return (
    <BrowserRouter>
      <Header searchFiled = {searchFiled}  />
      {searchFiled == true ? <SearchNFTS/>:null}
      <Routes><Route path="/" element={<Home/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/signup" element={<Signup/>}></Route>
        <Route path="/forgetPassword" element={<ForgetPassword/>}></Route>
        <Route path="/connect-wallet" element={<ConnectWallet connectexsSport={props?.connectexsSport}/>}></Route>
        <Route path="/marketplace" element={< Cards/>}></Route>
     </Routes>
     <Footer/>
     </BrowserRouter>
  )
}

export default Router
