import axios from '../axiosConfig/axios'
export const user = {
    login:(data,callback)=>{
        axios.post('/login',{
            email:data?.email,
            password:data?.password,
        },
        {
            headers:{
                'Content-Type': 'application/json',
            }
        }
        )
        .then((data)=>{
         console.log(data)
         callback(null,data)
        }).catch(err=>{
            console.log(err)
            callback(err,null)
        })
    },
    register:(data,callback)=>{
        axios.post('/register',{
            email:data?.email,
            password:data?.password,
        },
        {
            headers:{
                'Content-Type': 'application/json',
            }
        }
        )
        .then((data)=>{
            console.log(data)
            callback(null,data)
           }).catch(err=>{
               console.log(err)
               callback(err,null)
           })
    },
    socialLogin:(data,callback)=>{
        axios.post('/social-login',{
            idToken:data?.idToken,
        },
        {
            headers:{
                'Content-Type': 'application/json',
            }
        }
        )
        .then((data)=>{
            console.log(data)
            callback(null,data)
           }).catch(err=>{
               console.log(err)
               callback(err,null)
           })
    },
    forgetPassword:(email,callback)=>{
        axios.post('/password/reset',{
            email:email,
        },
        {
            headers:{
                'Content-Type': 'application/json',
            }
        }
        )
        .then((data)=>{
            console.log(data)
            callback(null,data)
           }).catch(err=>{
               console.log(err)
               callback(err,null)
           })
    }
}