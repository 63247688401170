import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  marketplacedata: null,
}

export const marketplaceReducer = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    getMarketplace: (state, action) => {
        console.log(action.payload)
      state.marketplacedata = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { getMarketplace } = marketplaceReducer.actions

export default marketplaceReducer.reducer