import React, { useState, useEffect } from 'react'
import { Assets } from '../../web3config/metadata';
import Auction from '../../utils/contractHandler/auctions'
import ShareLink from 'react-facebook-share-link'
import Web3Modal from "web3modal"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { TwitterShareButton, FacebookShareButton } from 'react-share';
import { Select } from 'antd';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getMarketplace } from './marketplaceReducer'
import './styles.css'
import $ from 'jquery';
import { nfts } from './apis/api'

import Cards from '../../utils/contractHandler/cards'
function Marketplace(props) {

    const marketplacedata = useSelector((state) => state.marketplace.marketplacedata)
    const dispatch = useDispatch()
    const [details, setDetails] = useState(true)
    const [nftsdata, setNftsdata] = useState([])
    const [originaldata,setOriginaldata] = useState()
    const [datanfts, setNfts] = useState([])
    const [selectednft, setNft] = useState({})
    const [loading, setLoading] = useState(false)
    const [limitnfts, setLimit] = useState(0)
    const [appyFilter, setApplyFilter] = useState([])
    const [orderFilter, setOrderFilter] = useState('asc')
    const [filters, setDilters] = useState({})
    const [currentCategory,setCurrentCategory] = useState('all')
    const [lowerLimit, setLowerLimit] = useState(props?.lower)
    const [upperlimit, setUpperLimit] = useState(props?.higher )
    const { Option } = Select;
    const Provider = {
        type: String,
        chain: 'polygon',
        provider: Object,
        ERC20: [],
        explorer: String,
        sockets: String,
        web3Mod: Object

    }
    const filteration = [
        {
            title: 'STATUS',
            filter: [{
                name: 'Latest',
                value: 'latest'
            },
            {
                name: 'Top Seller',
                value: 'topSeller'
            }
            ]
        },
        {
            title: 'CURRENCY',
            filter: [{
                name: 'EXS',
                value: 'exs'
            },
            {
                name: 'USDT',
                value: 'usdt'
            }
            ]
        },
        {
            title: 'Network',
            filter: [{
                name: 'Ethereum',
                value: 'ethereum'
            },
            {
                name: 'Polygon',
                value: 'polygon'
            }
            ]
        }
    ]

    const sortingOption = [
        {
            value: "asc",
            label: "Low to High"
        },
        {
            value: "desc",
            label: "High to Low"
        }]

    const categoryOption = [{
        label: "Muaythai",
        value: "muaythai"
    },
    {
        label: "Jiu-Jitsu",
        value: "jujitsu"
    },
    {
        value: "Soccer",
        value: "soccer"
    },
    {
        label: "Fitness",
        value: "fitness"
    },
    {
        label: "MMA",
        value: "mma"
    },
    {
        label: "Basketball",
        value: "basketball"
    },
    {
        label: "E-Sports",
        value: "esports"
    },
    {
        label: "Powerlifting",
        value: "powerlifting"
    },
    {
        label: "Boxing",
        value: "boxing",

    }
        ,
    {
        label: "Equestrian",
        value: "equestrian",

    },
    {
        label: "All",
        value: "all",

    }
    ]


    const initialState = {
        balancesUpdated: false,
        cardsLoaded: false,
        initializedStore: false,
        sports: {
            avalaible: ['soccer', 'muaythai', 'jujitsu', 'fitness'],
        },
        cards: {
            muaythai: [],
            jujitsu: [],
            fitness: [],
            soccer: [],
            freestyle: [],
        },
        latestAuctions: [],
        totalAuctions: {
            muaythai: 0,
            jujitsu: 0,
            fitness: 0,
            soccer: 0,
            freestyle: 0,
        },
        typeFreestyle: ['freestyle', 'Trickshot', "1 v 1", "Character"],
    }



    const setFilterCategories = () => {
        if(Object.keys(filters)?.length == 0){
            // if(currentCategory!= "all"){
            //     searchByCategory(currentCategory)
            // }
            // else{
            setNfts(originaldata?.slice(0, limitnfts))
            return
            // }
        }
        var data = []
        if(currentCategory == "all"){
            data = originaldata?.length > limitnfts ? originaldata?.slice(0, limitnfts) : originaldata
        }
        else{
            data = datanfts
        }
        var filterdata = []
        Object.keys(filters)?.map(filter => {
            if (filter == "STATUS") {
                const newValues = data?.filter(item => filters[filter][0]?.includes(item?.status))
                filterdata = [...filterdata, ...newValues]
            }
            else if (filter == "CURRENCY") {
                const newValues = data?.filter(item => filters[filter][0]?.includes(item?.currency))
                filterdata = [...filterdata, ...newValues]

            }
            else if (filter == "Network") {
                const newValues = data?.filter(item => filters[filter][0]?.includes(item?.network))
                filterdata = [...filterdata, ...newValues]

            }
            else if (filter == "PRICE") {
                if ('min' in filters[filter]) {
                    const newValues = data?.filter(item => filters[filter]?.min <= parseInt(item?.pricePerCard))
                    filterdata = [...filterdata, ...newValues]
                }
                else if ('max' in filters[filter]) {
                    const newValues = data?.filter(item => filters[filter]?.max >= parseInt(item?.pricePerCard))
                    filterdata = [...filterdata, ...newValues]
                }
                else if ('min' in filters[filter] && 'max' in filters[filter]) {
                    const newValues = data?.filter(item => filters[filter]?.max >= parseInt(item?.pricePerCard) && filters[filter]?.min <= parseInt(item?.pricePerCard))
                    filterdata = [...filterdata, ...newValues]
                }
            }
        })
        console.log(data)
        console.log(filterdata)
        setNfts([...filterdata])


    }

    useEffect(() => {
        // if (marketplacedata == null) {
            getNftsdata()
        // } else {
        //     console.log(marketplacedata)
        //     // setNfts(marketplacedata)
        //     // setNftsdata(marketplacedata)
        //     setLoading(true)
        //     setAuction(marketplacedata?.auctions, marketplacedata?.data)
        // }
    }, [])

    useEffect(() => {

    }, [marketplacedata])


    useEffect(() => {
        setFilterCategories()

    }, [filters])

    const getNftsdata = async () => {
        setLoading(true)
        nfts.getNFT((err, data) => {
            updateAuctions(data)
        })
    }
    const loadMore = () => {
        const datapoinst = originaldata?.length < 20 ? originaldata : originaldata?.slice(datanfts?.length, originaldata?.length - datanfts?.length > 20 ? datanfts?.length + 20 : originaldata?.length - datanfts?.length)
        console.log([...datanfts, ...datapoinst]);
        setNfts([...datanfts, ...datapoinst])
        setLimit([...datanfts, ...datapoinst]?.length)
    }
    const compareASC = (priceA, priceB) => {
        return parseFloat(priceA.price) - parseFloat(priceB.price);
    }

    const compareDESC = (priceA, priceB) => {
        return parseFloat(priceB.price) - parseFloat(priceA.price);
    }

    const sortByVAalue = (value) => {
        setOrderFilter(value)
        if (value == "desc") {
            let desc = datanfts?.sort(compareDESC)
            setNftsdata(desc)
        }
        else {
            let asc = datanfts?.sort(compareASC)
            setNftsdata(asc)
        }

    }
    const copyLink = () => {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }
    const embedCode = () => {
        const el = document.createElement("a");
        el.href = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }
    const searchByCategory = (value,data=null) => {
        // const filterdata = datanfts?.slice(0, limitnfts)?.filter(item => item?.properties?.typesport == value)
        setCurrentCategory(value)
        if(data != null){
           
        }
        if(value == "all"){
            setAuction(marketplacedata?.auctions, marketplacedata?.data)
        }
        else{
            const filterdata = originaldata?.filter(item => item?.properties?.typesport == value)
            setNfts(filterdata)
        }
        

    }
    const setAuction = async (data, acutions) => {
        const finalAuctions = data?.map(item => {
            const currentitem = acutions?.find(act => act.tokenId == item?.tokenId)
            if (currentitem != undefined) {
                return { ...currentitem, ...item, imageUrl: `https://img.ex-sports.io/imgs/${item?.tokenId}.${currentitem?.properties?.type}`, name: currentitem?.properties?.name, price: parseInt(item?.pricePerCard) }
            }
        })
        if (finalAuctions?.length > 20) {
            setNftsdata(finalAuctions?.slice(0, 20))
            setLimit(20)
        }
        else {
            setNftsdata(finalAuctions)
        }
        setNfts(finalAuctions.slice(props?.lower,props?.higher))
        setOriginaldata(finalAuctions)
        console.log(finalAuctions)
        setLoading(false)
    }

    const updateAuctions = async (data) => {
        const onions = Assets[Provider.chain].ONION
        onions.forEach(async onion => {
            const auctions = await Auction.onionAuctions(onion, Assets[Provider.chain].ERC20)
            setAuction(auctions, data?.data?.data)
            dispatch(getMarketplace({ auctions: auctions, data: data?.data?.data }))
        })
    }
    useEffect(() => {
    }, [document.getElementById("orderid")?.value])
    return (
        details == true ? <section className={`collection-explore pt-sm-90 marketplace-sec ${datanfts?.length == 0 ? 'datalength':""}`}>
            {loading == true ? <div id="loading" class="loading-section">
                <div class="loading-spinner">
                    <img src="assets/images/exs-loader.svg" alt="loader-logo" />
                </div>

            </div> : <div class={`overlay py-3 py-lg-5 position-relative ${datanfts?.length == 0 ? 'datalength':""}`}>
                <div class="container-fluid pe-3 pe-lg-5 py-lg-3">
                    <div class="row py-3">
                        <div class="col-md-4 mb-3 mb-md-0">
                            <div class="col-inner sec-main-heaidng filter-title">
                                {props?.home != true ? <div class="open-filter" id="open-filter-dskp" onClick={() => {
                                    $('#open-filter, #open-filter-dskp').click(function () {
                                        $('.filter-side').addClass('active');
                                    });

                                    $('.close-filter').click(function () {
                                        $('.filter-side').removeClass('active');
                                    });

                                    $('.select-btn, .outline-collection').click(function () {
                                        $(this).toggleClass('active');
                                    });

                                }}>
                                    <i class="fas fa-angle-right"></i>
                                </div> : null}
                                <div class="filter-hd-content d-inline-block">
                                    <h1 class="mb-1">
                                        Explore
                                    </h1>
                                    <p class="text-light">
                                        {datanfts?.length} Results
                                    </p>
                                </div>
                            </div>
                        </div>

                        {props?.home != true ? <div class="col-md-8 hide-xsm">
                            <div class="col-inner sort-col text-end ">
                                <Select placeholder="Select Categories"
                                    className='mr-5'
                                    style={{ width: 200, marginRight: '10px' }}
                                    optionFilterProp="children"
                                    onChange={(e) => { searchByCategory(e) }}
                                >
                                    {
                                        categoryOption?.map(item => {
                                            return <Option value={item?.value}>{item?.label}</Option>
                                        })
                                    }
                                </Select>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Sort By Price"
                                    optionFilterProp="children"
                                    onChange={(e) => { sortByVAalue(e) }}
                                >{
                                        sortingOption?.map(item => {
                                            return <Option value={item?.value}>{item?.label}</Option>
                                        })
                                    }
                                </Select>


                            </div>
                        </div> : null}

                        <div class="col-md-8 d-md-none">
                            <div class="col-inner sort-col row mx-0">
                                <div class="col-6">
                                    <Select placeholder="Select Categories"
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        onChange={(e) => { searchByCategory(e) }}
                                    >
                                        {
                                            categoryOption?.map(item => {
                                                return <Option value={item?.value}>{item?.label}</Option>
                                            })
                                        }
                                    </Select>
                                </div>

                                <div class="col-6">
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder="Sort By Price"
                                        optionFilterProp="children"
                                        onChange={(e) => { sortByVAalue(e) }}
                                    >
                                        {
                                            sortingOption?.map(item => {
                                                return <Option value={item?.value}>{item?.label}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div class="row mt-3 mx-0">
                                <div class="col-12">
                                    <button class="btn btn-light-outline w-100" id="open-filter">
                                        Open Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid px-3 px-lg-5 pb-lg-3">

                    <div class="collection-card-row">
                        <div class="row justify-content-center">
                            {
                                datanfts?.map(item => {
                                    return <div class="col-6 col-md-4 col-xl-3 mb-3">
                                        <div class="card" onClick={() => {
                                            setNft(item)
                                            setDetails(false)
                                        }}>

                                            {item?.properties?.type == "Webm" ? <div class="card-imgs text-center">
                                                <video class="h-100" autoplay="autoplay" loop="loop" muted="muted">
                                                    <source src={`https://img.ex-sports.io/videos/${item?.tokenId}.webm`} type="video/webm" />
                                                    <source src={`https://img.ex-sports.io/videos/${item?.tokenId}.mp4`} type="video/mp4" />
                                                </video>
                                            </div> : <img class="card-img" src={item?.imageUrl} alt="card-img" />}
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>{item?.name}</p>
                                                        {/* <p><span class="title">by Exacto</span></p> */}
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                {item?.pricePerCard} {item?.currency?.toUpperCase()}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    {props?.home != true ?
                        originaldata?.length > datanfts?.length   && datanfts.length > 19 ? <div class="row pt-4">
                            <div class="col-12 text-center pt-4" onClick={(e) => {
                                e.preventDefault()
                                loadMore()
                            }}>
                                <a href="#" class="btn btn-light-outline w-auto">
                                    Load More
                                </a>
                            </div>
                        </div> : null :
                        <div class="row pt-4">
                            <div class="col-12 text-center pt-4">
                                <Link to="/marketplace" class="view-market">
                                    View all marketplace
                                </Link>
                            </div>
                        </div>
                    }
                </div>

                <div class="filter-side">
                    <div class="filter-inner">
                        <div class="filter-head py-3 border-bottom">
                            <div class="filter-heading">
                                Filter <span class="clear-filter" onClick={() => {
                                    // setApplyFilter([])
                                    setDilters({})
                                }}>Clear</span>
                                <button class="close-btn close-filter">
                                    <i class="fas fa-angle-left d-none d-lg-block"></i>
                                    <i class="fas fa-times d-lg-none"></i>
                                </button>
                            </div>
                        </div>
                        <div class="filter-body py-3 py-lg-4">{
                            filteration?.map(item => {
                                return <div class="filter-row mb-3 row">
                                    <label>{item?.title}</label>
                                    {
                                        item?.filter?.map(filter => {
                                            return <div class="col">
                                                <button onClick={() => {

                                                    setDilters({ ...filters, [item?.title]: [filter?.value] })
                                                }} class={`btn select-btn ${filters?.[item?.title]?.includes(filter?.value) ? 'active' : ''}`}>
                                                    {filter?.name}
                                                </button>
                                            </div>
                                        })
                                    }


                                </div>

                            })
                        }

                            <div class="filter-row mb-3 row">
                                <label>Price</label>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="Min Price" onChange={(e) => {
                                                setDilters({
                                                    ...filters, PRICE: {
                                                        ...filters?.['PRICE'],
                                                        min: e.target.value
                                                    }
                                                })
                                            }} />
                                        </div>
                                        <div class="col">
                                            <input type="number" class="form-control" placeholder="Max Price" onChange={(e) => {
                                                setDilters({
                                                    ...filters, PRICE: {
                                                        ...filters?.['PRICE'],
                                                        max: e.target.value
                                                    }
                                                })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="filter-row mb-3 row">
                                    <label>Collections</label>
                                    <div class="col">
                                        <input type="number" class="form-control" placeholder="Search" />
                                    </div>
                                </div> */}

                            {/* <div class="filter-row my-3 row">
                                    <div class="col-12 mb-2">
                                        <button class="btn outline-collection">
                                            MARADONA COLLECTION
                                            <span>#Soccer</span>
                                        </button>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <button class="btn outline-collection">
                                            SEAN GARNIER COLLECTION
                                            <span>#Soccer</span>
                                        </button>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <button class="btn outline-collection">
                                            MARADONA COLLECTION
                                            <span>#Soccer</span>
                                        </button>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <button class="btn outline-collection">
                                            SEAN GARNIER COLLECTION
                                            <span>#Soccer</span>
                                        </button>
                                    </div>
                                </div> */}

                        </div>
                    </div>
                </div>

            </div>}
        </section> :
            <>
                <section class="collection-explore pt-sm-90">
                    <div class="overlay py-3 py-lg-5 position-relative">

                        <div class="container py-3 py-md-4">
                            <section class="marketplace-detail">
                                <div class="row">
                                    <div class="col-lg-4 mb-3 mb-lg-0">
                                        <div class="card detail-card">
                                            <img src={selectednft?.imageUrl} alt="card-img" />
                                        </div>
                                    </div>
                                    <div class="col-lg-8 ps-lg-5 mb-3 mb-lg-0">
                                        <div class="detail-header">
                                            <h1>{selectednft?.name}
                                            </h1>
                                            <button class="cross-btn" onClick={() => {
                                                setNft({})
                                                setDetails(true)
                                            }}>
                                                <i class="fas fa-times"></i>
                                            </button>
                                            <div class="dropdown">
                                                <button class="share-btn dropdown-toggle" id="sharedd" data-bs-toggle="dropdown" onClick={() => {
                                                    copyLink()
                                                }}>
                                                    <i class="fas fa-share-alt"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="sharedd">
                                                    <li>
                                                        <a href="#" class="dropdown-item">
                                                            <i class="fas fa-link me-1"></i> Copy link
                                                        </a>
                                                    </li>
                                                    <ShareLink link={window?.location?.href}>
                                                        {link => (
                                                            <li>
                                                                {/* <a href={link} class="dropdown-item" data-bs-toggle="modal" data-bs-target="#select-cardModal">
                                                                    <i class="fab fa-facebook me-1"></i> Share on Facebook
                                                                </a>*/}
                                                                <a href={link} class="dropdown-item" target='_blank'>
                                                                    <i class="fab fa-facebook me-1"></i> Share on Facebook
                                                                </a>
                                                            </li>
                                                        )}

                                                    </ShareLink>
                                                    {/* <li>
                                                         <a href={link} class="dropdown-item" data-bs-toggle="modal" data-bs-target="#select-cardModal">
                                                                    <i class="fab fa-facebook me-1"></i> Share on Facebook
                                                                </a>
                                                        <a href="#" class="dropdown-item" target='_blank'>
                                                            <FacebookShareButton url={window.location.href}>
                                                            <i class="fab fa-facebook me-1"></i> Share on Facebook
                                                            </FacebookShareButton>
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a href="#" class="dropdown-item">
                                                            <TwitterShareButton url={window.location.href}>
                                                                <i class="fab fa-twitter me-1"></i>  Share on Twitter
                                                            </TwitterShareButton>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" class="dropdown-item" onClick={() => {
                                                            embedCode()
                                                        }}>
                                                            <i class="fas fa-code me-1"></i> Embed Item
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="detail-body">
                                            <ul class="nav nav-tab" id="game-tab">
                                                <li class="nav-item">
                                                    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#card-info">
                                                        card info
                                                    </button>
                                                </li>
                                                {/* <li class="nav-item">
                                                    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#athlite-info">
                                                        Athlite info
                                                    </button>
                                                </li> */}
                                            </ul>
                                            <div class="tab-content" id="game-tab-content">
                                                <div class="tab-pane fade show active" id="card-info">
                                                    <div class="row mb-3">
                                                        <div class="col-5 col-md-3">
                                                            <div class="title">
                                                                sport
                                                            </div>
                                                        </div>
                                                        <div class="col-7 col-md-9">
                                                            <div class="name">
                                                                {selectednft?.properties?.typesport}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-5 col-md-3">
                                                            <div class="title">
                                                                Country
                                                            </div>
                                                        </div>
                                                        <div class="col-7 col-md-9">
                                                            <div class="name">
                                                                {selectednft?.properties?.nationality}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-5 col-md-3">
                                                            <div class="title">
                                                                Edition
                                                            </div>
                                                        </div>
                                                        <div class="col-7 col-md-9">
                                                            <div class="name">
                                                                Elite Fight Night
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-5 col-md-3">
                                                            <div class="title">
                                                                Card ID
                                                            </div>
                                                        </div>
                                                        <div class="col-7 col-md-9">
                                                            <div class="name">
                                                                {selectednft?.tokenId}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane" id="athlite-info">
                                                    <div class="row mb-3">
                                                        <div class="col-6 col-md-5">
                                                            <div class="title">
                                                                Number of Matches
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-md-7">
                                                            <div class="name">
                                                                84
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-6 col-md-5">
                                                            <div class="title">
                                                                Victories
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-md-7">
                                                            <div class="name">
                                                                72
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-6 col-md-5">
                                                            <div class="title">
                                                                previous Matches
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-md-7">
                                                            <div class="name">
                                                                <a href="#">
                                                                    www.ex-sports.io/tv/matches132
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="price-section mt-4">
                                                <div class="price-detail d-flex align-items-center">
                                                    {selectednft?.currency != "usdt" ? <img class="me-1" src="../assets/images/icons/Ex-coin.svg" alt="coin-img" /> :
                                                        <img class="me-1" src="assets/images/icons/tether.svg" alt="coin-img" />}
                                                    <span class="ex-price me-2">
                                                        {selectednft?.pricePerCard} {selectednft?.currency?.toUpperCase()}
                                                    </span>
                                                    <span class="discount">
                                                        (~1024$)
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="buy-button mt-4">
                                                <button class="btn btnm-primary " data-bs-toggle="modal" data-bs-target="#Buy-NFT-table" onClick={() => {
                                                    //  $('#Buy-NFT-table').modal('show');
                                                }}>
                                                    Buy Now
                                                </button>
                                            </div>

                                            <div class="transaction mt-4 mt-lg-5">
                                                <div class="transaction-header position-relative py-3 py-md-4">
                                                    <h2 class="transaction-hd">
                                                        Transaction
                                                    </h2>
                                                </div>
                                                <div class="transaction-body py-3 py-md-4">
                                                    <div class="row mb-3">
                                                        <div class="col-md-8 mb-3 mb-md-0">
                                                            <ul class="nav">
                                                                <li class="nav-item pe-3">
                                                                    <div class="profile-img">

                                                                    </div>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <h2>Acquired form Ekachai</h2>
                                                                    <p class="date-time">
                                                                        22/4/2022, 4:18
                                                                    </p>
                                                                    <p class="copy">
                                                                        PM1844abbec1ecceb7a78vf98956c01
                                                                        <span class="copy-lnk">
                                                                            <i class="far fa-copy"></i>
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div class="col-md-4 text-end">
                                                            <p class="c-num">
                                                                #1254836946
                                                            </p>
                                                            <p class="coin-price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-md-8 mb-3 mb-md-0">
                                                            <ul class="nav">
                                                                <li class="nav-item pe-3">
                                                                    <div class="profile-img">

                                                                    </div>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <h2>Acquired form Leesu</h2>
                                                                    <p class="date-time">
                                                                        22/4/2022, 4:18
                                                                    </p>
                                                                    <p class="copy">
                                                                        PM1844abbec1ecceb7a78vf98956c01
                                                                        <span class="copy-lnk">
                                                                            <i class="far fa-copy"></i>
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div class="col-md-4 text-end">
                                                            <p class="c-num">
                                                                #1254836946
                                                            </p>
                                                            <p class="coin-price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                80.00 EXS
                                                            </p>
                                                        </div>

                                                    </div>

                                                    <div class="row mb-3">
                                                        <div class="col-md-8 mb-3 mb-md-0">
                                                            <ul class="nav">
                                                                <li class="nav-item pe-3">
                                                                    <div class="profile-img">

                                                                    </div>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <h2>Creator by EX-Sports</h2>
                                                                    <p class="date-time">
                                                                        22/4/2022, 4:18
                                                                    </p>
                                                                    <p class="copy">
                                                                        PM1844abbec1ecceb7a78vf98956c01
                                                                        <span class="copy-lnk">
                                                                            <i class="far fa-copy"></i>
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div class="col-md-4 text-end">
                                                            <p class="c-num">
                                                                #1254836946
                                                            </p>
                                                            <p class="coin-price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                60.00 EXS
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="container-fluid">
                            <div class="row py-3">
                                <div class="col-12 headertitle">
                                    <div class="col-inner">
                                        <h1>
                                            You may also like
                                        </h1>
                                    </div>
                                    <div className='navigator-container'>
                                        <button className='navigator'><i className='fas fa-angle-left'></i></button>
                                        <button className='navigator'><i className='fas fa-angle-right'></i></button>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                {/* <div class="card-slider owl-carousel marketplace-owl-slider">

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image7.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image8.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image9.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image10.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image11.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card card-item">
                                            <div class="card-imgs">
                                                <img src="assets/images/card-imgs/image12.png" alt="card-img" />
                                            </div>
                                            <div class="card-content">
                                                <div class="row">
                                                    <div class="col-md-6 mb-3 mb-md-0">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div> */}
                                <div class="col-6 col-md-4 col-xl-3 mb-3">
                                    <div class="card youknowme" onClick={() => {
                                        // setNft(item)
                                        // setDetails(false)
                                    }}>

                                        <img class="card-img" src='https://img.ex-sports.io/imgs/15.png' alt="card-img" />
                                        <div class="card-caption-text">
                                            <div class="row">

                                                <div class="col-md-6 mb-3 mb-md-0">
                                                    <p>'90 MATCH-WORN</p>
                                                    <p><span class="title">by Exacto</span></p>
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <p><span class="title">Current price</span></p>
                                                    <p>
                                                        <span class="price">
                                                            <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                            100 USD
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-4 col-xl-3 mb-3 ">
                                    <div class="card youknowme" onClick={() => {
                                        // setNft(item)
                                        // setDetails(false)
                                    }}>

                                        <img class="card-img" src='https://img.ex-sports.io/imgs/15.png' alt="card-img" />
                                        <div class="card-caption-text">
                                            <div class="row">
                                                <div class="col-md-6 mb-3 mb-md-0">
                                                    <p>I am </p>
                                                    {/* <p><span class="title">by Exacto</span></p> */}
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <p><span class="title">Current price</span></p>
                                                    <p>
                                                        <span class="price">
                                                            <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                            100 USD
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-4 col-xl-3 mb-3">
                                    <div class="card youknowme" onClick={() => {
                                        // setNft(item)
                                        // setDetails(false)
                                    }}>

                                        <img class="card-img" src='https://img.ex-sports.io/imgs/15.png' alt="card-img" />
                                        <div class="card-caption-text">
                                            <div class="row">
                                                <div class="col-md-6 mb-3 mb-md-0">
                                                    <p>I am </p>
                                                    {/* <p><span class="title">by Exacto</span></p> */}
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <p><span class="title">Current price</span></p>
                                                    <p>
                                                        <span class="price">
                                                            <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                            100 USD
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-4 col-xl-3 mb-3">
                                    <div class="card youknowme" onClick={() => {
                                        // setNft(item)
                                        // setDetails(false)
                                    }}>

                                        <img class="card-img" src='https://img.ex-sports.io/imgs/15.png' alt="card-img" />
                                        <div class="card-caption-text">
                                            <div class="row">
                                                <div class="col-md-6 mb-3 mb-md-0">
                                                    <p>I am </p>
                                                    {/* <p><span class="title">by Exacto</span></p> */}
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <p><span class="title">Current price</span></p>
                                                    <p>
                                                        <span class="price">
                                                            <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                            100 USD
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-6 col-md-4 col-xl-3 mb-3">
                                            <div class="card youknowme" onClick={() => {
                                                // setNft(item)
                                                // setDetails(false)
                                            }}>

                                                <img class="card-img" src='https://img.ex-sports.io/imgs/15.png' alt="card-img" />
                                                <div class="card-caption-text">
                                                    <div class="row">
                                                        <div class="col-md-6 mb-3 mb-md-0">
                                                            <p>I am </p>
                                                            {/* <p><span class="title">by Exacto</span></p> 
                                                        </div>
                                                        <div class="col-md-6 text-end">
                                                            <p><span class="title">Current price</span></p>
                                                            <p>
                                                                <span class="price">
                                                                    <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                    100 USD
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                            </div>

                        </div>
                        <div class="container-fluid d-none">
                            <div class="row py-4 justify-content-center">
                                <div class="owl-slider marketplace-owl-slider">
                                    <div class="collection-card-row">
                                        <div class="card">
                                            <img class="card-img" src="assets/images/card-imgs/image7.png" alt="card-img" />
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <img class="card-img" src="assets/images/card-imgs/image8.png" alt="card-img" />
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p>by Exacto</p>
                                                    </div>
                                                    <div class="col text-end">
                                                        <p>Current price</p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <img class="card-img" src="assets/images/card-imgs/image9.png" alt="card-img" />
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <img class="card-img" src="assets/images/card-imgs/image10.png" alt="card-img" />
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p>by Exacto</p>
                                                    </div>
                                                    <div class="col text-end">
                                                        <p>Current price</p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <img class="card-img" src="assets/images/card-imgs/image11.png" alt="card-img" />
                                            <div class="card-caption-text">
                                                <div class="row">
                                                    <div class="col">
                                                        <p>'90 MATCH-WORN</p>
                                                        <p><span class="title">by Exacto</span></p>
                                                    </div>
                                                    <div class="col text-end">
                                                        <p><span class="title">Current price</span></p>
                                                        <p>
                                                            <span class="price">
                                                                <img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic" />
                                                                100.00 EXS
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="modal fade Ex-modal" id="select-cardModal" tabIndex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-bottom-0">
                                <h1>SELECT</h1>
                                <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center pb-5">
                                <div class="row jutify-content-center">
                                    <div class="col">
                                        <div class="img-item mb-3">
                                            <img src="assets/images/card-imgs/image8.png" alt="card-img" />
                                        </div>
                                        <div class="content-sec mb-3">
                                            <h1 class="mb-4">
                                                BUAKAW BANCHAMEK
                                            </h1>
                                            <a href="#" class="btn w-100 btn-light-outline mb-3">
                                                VIEW
                                            </a>
                                            <a href="#" class="btn w-100 btn-light-outline mb-3">
                                                BUY
                                            </a>

                                            <div class="copy-link">
                                                www.ex-sports.io/buakaw_banchamek
                                                <span><i class="far fa-copy"></i></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade Ex-modal" id="Buy-NFT-table" tabIndex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header border-bottom-0">
                                <h1>SELECT AND BUY</h1>
                                <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center pb-5">
                                <div class="row jutify-content-center">
                                    <div class="col">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Owner</th>
                                                        <th>NFT ID</th>
                                                        <th>Crypto Price</th>
                                                        <th>USD Price</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline" data-bs-target="#checkout-modal" data-bs-toggle="modal" data-bs-dismiss="modal">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="owner">@Ex-Sports</td>
                                                        <td>#12</td>
                                                        <td>0.44 wETH</td>
                                                        <td>$ 1,250</td>
                                                        <td><button class="btn btn-light-outline">BUY</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade Ex-modal" id="checkout-modal" tabIndex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-bottom-0">
                                <h1>SELECT</h1>
                                <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center pb-5">
                                <div class="row jutify-content-center">
                                    <div class="col">
                                        <div class="img-item mb-3">
                                            <img src="assets/images/card-imgs/image8.png" alt="card-img" />
                                        </div>
                                        <div class="content-sec nft-recp mb-3">
                                            <div class="recp-col border-bottom pb-2">
                                                <div class="row">
                                                    <div class="col text-start">
                                                        <h1>
                                                            BUAKAW BANCHAMEK
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="recp-col border-bottom pb-4">
                                                <div class="row">
                                                    <div class="col-6  text-start">
                                                        Token ID
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        #23
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="recp-col border-bottom pb-4">
                                                <div class="row">
                                                    <div class="col-6 text-start">
                                                        Your Balance
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <b>2400.00 EXS</b>
                                                        <br />
                                                        <span class="r-amount">($2400)</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="recp-col border-bottom pb-4">
                                                <div class="row">
                                                    <div class="col-6 text-start">
                                                        Fixed Price
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <b>100.00 EXS</b>
                                                        <br />
                                                        <span class="r-amount">($100)</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="recp-col pb-4">
                                                <div class="row">
                                                    <div class="col-6 text-start">
                                                        Total
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <span class="total-amount">100.00 EXS</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="recp-col pb-4">
                                                <div class="row mx-0">
                                                    <div class="form-check mb-4 mb-md-5">
                                                        <input class="form-check-input" type="checkbox" value="" id="agree-tc" />
                                                        <label class="form-check-label text-start" htmlFor="agree-tc">
                                                            By checking this box, I agree to Ex-Sport <a href="#">Term of Service</a>
                                                        </label>
                                                    </div>
                                                </div>

                                                <a href="purchase-completed.php" class="btn w-100 btn-light-outline mb-3 text-uppercase">
                                                    Confirm Checkout
                                                </a>

                                                <div class="recp-col submit-checkout">
                                                    <div class="row">
                                                        <div class="col text-center">
                                                            <img src="assets/images/ex-sports-logo.png" alt="ex-logo" />
                                                            <div class="porcessing">
                                                                Processing....
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default Marketplace
