import React from 'react'
import {filterNFT} from '../../configurations/filter'
import {order} from '../../configurations/order'
import {Link} from 'react-router-dom'
import MarketPlace from '../marketplace/marketplace'
function HotCollection() {
    return (
        <section class="collection-explore">
		<div class="overlay py-5">
			<div class="container py-3">
				<div class="row py-3">
					<div class="col-12">
						<div class="col-inner">
							<h1>
								Hot collections
							</h1>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="card-slider owl-carousel owl-card-slider">
						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image1.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image2.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image3.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image4.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image3.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

						<div class="card card-item">
							<div class="card-imgs">
								<img src="assets/images/card-imgs/image4.png" alt="card-img"/>
							</div>
							<div class="card-content">
								<h6>
									Diego Maradona
								</h6>
								<p>
									Paul Gerben Maradona Portrait
								</p>
								<a href="#">
									Buy Now
								</a>
							</div>
						</div>

					</div>
					
				</div>

			</div>
			{/* <div class="container py-3">
				<div class="row py-3">
					<div class="col-md-4">
						<div class="col-inner">
							<h1>
								Explore
							</h1>
						</div>
					</div>
					<div class="col-md-8">
						<div class="col-inner sort-col text-end">
							<select class="n-select">
								<option value = "placeholder">Categories</option>
								{
									filterNFT?.map(item=>{
										return <option value = {item?.value}>{item?.name}</option>
									})
								}
							</select>

							<select class="n-select ml-1" style={{marginLeft:'10px'}}>
								<option value = "placeholder">Sort by</option>
								{
									order?.map(item=>{
										return <option value = {item?.value}>{item?.name}</option>
									})
								}
							</select>

						</div>
					</div>
				</div>

				<div class="collection-card-row">
					<div class="row justify-content-center">

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image7.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image8.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p>by Exacto</p>
										</div>
										<div class="col text-end">
											<p>Current price</p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image9.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image10.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p>by Exacto</p>
										</div>
										<div class="col text-end">
											<p>Current price</p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image11.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image12.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p>by Exacto</p>
										</div>
										<div class="col text-end">
											<p>Current price</p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image13.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image14.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p>by Exacto</p>
										</div>
										<div class="col text-end">
											<p>Current price</p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="row pt-4">
					<div class="col-12 text-center pt-4">
						<Link to="/marketplace" class = "view-market">
							View all marketplace
						</Link>
					</div>
				</div>
			</div> */}
		</div>
		
	</section>
    )
}

export default HotCollection
