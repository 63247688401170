import React from 'react'

function TVSupport() {
  return (
    <section class="ex-tv  ex-tv-desktop">
		<div class="overlay py-5">
			<div class="container-fluid py-3 px-0">
				<h1 class="text-center">
					Ex Support Tv
				</h1>

				<div class="row">
					<div class="ex-tv-slider owl-carousel">
						<div class="ex-item">
							<div class="ex-tv-img">
								<img src="assets/images/ex-tv/www.ex-sports-1.jpg" alt="ex-tv-banner"/>
							</div>
							<div class="ex-tv-content">
								<h1> 
									TK FIGHT NIGHT SOCIAL KNOCKOUT 2
								</h1>
								<p>
									<img src="assets/images/icons/date-ic.svg" alt="date-icon"/> 
									FRIDAY, OCTOBER 15 2021
								</p>
								<p>
									<img src="assets/images/icons/clock-ic.svg" alt="clock-icon"/>
									7:00 PM ( GMT +4 )
								</p>
								<p>
									<img src="assets/images/icons/map-marker-ic.svg" alt="map-icon"/>
									DUBAI , UAE
								</p>
							</div>
						</div>

						<div class="ex-item">
							<div class="ex-tv-img">
								<img src="assets/images/ex-tv/www.ex-sports-1.jpg" alt="ex-tv-banner"/>
							</div>
							<div class="ex-tv-content">
								<h1> 
									TK FIGHT NIGHT SOCIAL KNOCKOUT 2
								</h1>
								<p>
									<img src="assets/images/icons/date-ic.svg" alt="date-icon"/> 
									FRIDAY, OCTOBER 15 2021
								</p>
								<p>
									<img src="assets/images/icons/clock-ic.svg" alt="clock-icon"/>
									7:00 PM ( GMT +4 )
								</p>
								<p>
									<img src="assets/images/icons/map-marker-ic.svg" alt="map-icon"/>
									DUBAI , UAE
								</p>
							</div>
						</div>

						<div class="ex-item">
							<div class="ex-tv-img">
								<img src="assets/images/ex-tv/www.ex-sports-1.jpg" alt="ex-tv-banner"/>
							</div>
							<div class="ex-tv-content">
								<h1> 
									TK FIGHT NIGHT SOCIAL KNOCKOUT 2
								</h1>
								<p>
									<img src="assets/images/icons/date-ic.svg" alt="date-icon"/> 
									FRIDAY, OCTOBER 15 2021
								</p>
								<p>
									<img src="assets/images/icons/clock-ic.svg" alt="clock-icon"/>
									7:00 PM ( GMT +4 )
								</p>
								<p>
									<img src="assets/images/icons/map-marker-ic.svg" alt="map-icon"/>
									DUBAI , UAE
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="contianer py-3">
				<div class="row">
					<div class="col-12 text-center">
						<a href="#" class="btn btn-light-outline">
							All Events
						</a>
					</div>
				</div>
			</div>

		</div>
	</section>
  )
}

export default TVSupport
