import React from 'react'

function Footer() {
  return (
    <section class="footer">
    <div class="overlay py-5">
        <div class="container py-3">
            <div class="row">
                <div class="col-lg-3 order-last order-lg-first">
                        <img class = "footer-logo" src="assets/images/ex-sports-logo.png" alt="comp-logo"/>
                        <p class = "pra-top">
                            EX Sport Starz is a platform that allows sport fans to buy, trade and sell digital sport collectibles. The app offers a variety of sports and has a special reward program for the collectors, athletes and sport fans.
                        </p>
                </div>
                <div class="col-lg-9 order-first order-lg-last mb-5 mb-lg-0">
                    <div class="row mx-0">
                        <div class="col-md-3">
                            <h1>
                                EX Suport
                            </h1>

                            <ul class="footer-nav">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Dashboard
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Marketplace
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        My Album
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        How to buy
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div class="col-md-3">
                            <h1>
                                More
                            </h1>

                            <ul class="footer-nav">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        About
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Blog
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Subscribe
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Contact
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div class="col-md-3">
                            <h1>
                                Channel
                            </h1>

                            <ul class="footer-nav">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        www.ex-sports.io
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        www.ex-sports.tv
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div class="col-md-3">
                            <h1>
                                Cummunity
                            </h1>

                            <ul class="footer-nav">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="fab fa-telegram me-1"></i> Telegram
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="fab fa-facebook me-1"></i> Facebook
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="fab fa-twitter me-1"></i> Twitter
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="fab fa-instagram me-1"></i> Instagram
                                    </a>
                                </li>
                            </ul>

                        </div>

                    </div>
                </div>
           </div>
        </div>
        <div class="container py-3">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="mb-0 pra-bottom">
                        EX Sports Pte. Ltd. 168 Robinson Road, #12-01 Capital Tower, Singapore 0068912
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Footer
