// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

// Your web app's Firebase configuration
const config = {
    apiKey: "AIzaSyBgL3IXEFW3Z3_QwAKDs18G6hC1TIRZM68",
    authDomain: "exsports-f2316.firebaseapp.com",
    projectId: "exsports-f2316",
    storageBucket: "exsports-f2316.appspot.com",
    messagingSenderId: "418767430204",
    appId: "1:418767430204:web:6f2f42079b543253d95939",
    measurementId: "G-F5Q5G9FW7K"
};

// Initialize Firebase
firebase.initializeApp(config)

const db = firebase.firestore()
const auth = firebase.auth();
const storage = firebase.storage()


export { firebase, db, auth, storage }