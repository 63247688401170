import React, { useState,useEffect } from 'react'
import {commonFunction } from '../../utitlities/utility'
import './styles.css'
import {user} from '../../api/user'
function Signup() {
    const [email,setEmail] = useState(false)
    const [password,setPassword] = useState(false)
    const [privacy,setPrivacy] = useState(false)
    const [error,setError] = useState("")
    const [showError,setShowError] = useState(false)
    useEffect(()=>{

    },showError)
    const registerUser = ()=>{
         const email = document.getElementById("email")?.value
         const password  =  document.getElementById("password")?.value
         const confirmPassword = document.getElementById("confirmPassword")?.value
         const privacyPolicy = document.getElementById("agreet")?.checked
         console.log(privacyPolicy);
        if(commonFunction.ValidateEmail(email) == false){
            setEmail(true)
            return
        }
        else{
            setEmail(false)
        }

        console.log(commonFunction.onlySpaces(email));

        if(commonFunction.onlySpaces(email) == true){
            setEmail(true)
            setError("Enter a valid Email")
            setShowError(true)
            setTimeout(() => {
                setError("")
                setShowError(false)
            }, 5000);
            return
        }
        else{
            setEmail(false)
        }
        if(commonFunction.checkPassword(password) == true){
            setEmail(true)
            return
        }
        else{
            setEmail(false)
        }
    
        if(password != confirmPassword){
            setPassword(true)
            setError("Your Confirm password doesn't match with Password")
            setShowError(true)
            setTimeout(() => {
                setError("")
                setShowError(false)
            }, 5000);
            return
        }
        else{
            setPassword(false)
        }
        
        if(password?.length < 8 ){
            setPassword(true)
            setError("Your password must have 8 character")
            setShowError(true)
            setTimeout(() => {
                setError("")
                setShowError(false)
            }, 5000);
            return
        }
        else{
            setPassword(false)
        }

        if(privacyPolicy == false){
            setPrivacy(true)
            return 
        }
        else{
             setPrivacy(false)
        }

        user.register({
            email:email,
            password:password,

        },(err,data)=>{
            console.log(data)
            if(data!= null){
                if('token' in data?.data){
                    setError("You are register successfully")
                    setShowError(true)
                    setTimeout(() => {
                        setError("")
                        setShowError(false)
                    }, 5000);
                }
                else{
                    setError("Something Went Wrong")
                    setShowError(true)
                    setTimeout(() => {
                        setError("")
                        setShowError(false)
                    }, 5000);
                }
            }
            else{
                setError("Something Went Wrong")
                setShowError(true)
                    setTimeout(() => {
                        setError("")
                        setShowError(false)
                    }, 5000);
            }

        })
    }
  return (
    <section class="login-signup-form bg-main">
        <div class="overlay py-5">
            <div class="container py-3 py-lg-4">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6">
                        <div class="form-section">
                            <h1>
                                sign up
                            </h1>
                            {showError == true ? <p style={{color:"red",fontSize:'15px'}}>{error}</p>:null}
                            <div class="form-group">
                                <label for="Email">Email</label>
                                <input type="email" class = {`form-control ${email ? 'error-color':''}`} id="email" />
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input type="password" class = {`form-control ${password ? 'error-color':''}`} id="password" 
                                    />
                            </div>
                            <div class="form-group">
                                <label for="password">Confirm Password</label>
                                <input type="password" class="form-control" id='confirmPassword' />
                            </div>

                            <div class="form-group">
                                <div class="form-check">
                                    <input class = {`form-check-input ${privacy ? 'error-color':''}`} type="checkbox" value="" id="agreet" />
                                    <label class="form-check-label" for="agreet">
                                        I agree to Ex-sports <a href = "#">Terms of Service</a>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="email-offers"/>
                                    <label class="form-check-label" for="email-offers">
                                        I agree to receive offers from Ex-sports Chain to 
                                        my email address 
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="submit" value="Create Account" class="form-control btnm-primary text-uppercase" onClick={()=>{
                                    registerUser()
                                }}/>
                            </div>

                            <div class="form-group">
                                <p class="text-center">
                                    Already a Member? <a href="/login">Log in</a>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Signup
