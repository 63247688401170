import React,{useState} from 'react'
import {commonFunction } from '../../utitlities/utility'
import { user } from '../../api/user'
function ForgetPassword(props) {
    const [email,setEmail] = useState(false)
    
    const  resetPassword = () =>{
             const email = document.getElementById("email")?.value
            if(commonFunction.ValidateEmail(email) == false){
                setEmail(true)
                return
            }
            else{
                setEmail(false)
            }
    
            console.log(commonFunction.onlySpaces(email));
    
            if(commonFunction.onlySpaces(email) == true){
                setEmail(true)
                return
            }
            else{
                setEmail(false)
            }
           
    
            user?.forgetPassword(email,(err,data)=>{
                console.log(data)
            })
        }
  return (
    <section class="login-signup-form bg-main">
    <div class="overlay py-5">
        <div class="container py-3 py-lg-4">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6">
                    <div class="form-section">
                        <h1>
                            Forgot password
                        </h1>

                        <div class="form-group">
                            <p>
                                Enter the email address associated with your account and we'll 
                                send you a link to reset your password.
                            </p>
                        </div>

                        <div class="form-group">
                            <label for="Email">Email</label>
                            <input type="text" class = {`form-control ${email ? 'error-color':''}`} id = "email"/>
                        </div>

                        <div class="form-group">
                            <button   class="form-control btnm-primary text-uppercase" onClick={(e)=>{
                                e.preventDefault()
                                resetPassword()
                            }}>Continue</button>
                        </div>

                        <div class="form-group">
                            <p class="text-center">
                                Don't have an account? <a href="/signup">Sign up</a>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default ForgetPassword
