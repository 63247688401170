import React from 'react'

const SearchNFT = () => {
  return (
    <section class="collection-explore">
		<div class="overlay py-5">
			<div class="container py-3">
				<div class="row py-3">
					<div class="col-md-4">
						<div class="col-inner">
							<h1>
								Search Results
							</h1>
                            <p class="text-light">
                                3 Results for <span class="filter-word">“Freitas”</span>
                            </p>
						</div>
					</div>
				</div>

				<div class="collection-card-row">
					<div class="row">

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image7.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image8.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p>by Exacto</p>
										</div>
										<div class="col text-end">
											<p>Current price</p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3 mb-3">
							<div class="card">
								<img class = "card-img" src="assets/images/card-imgs/image9.png" alt="card-img"/>
								<div class="card-caption-text">
									<div class="row">
										<div class="col">
											<p>'90 MATCH-WORN</p>
											<p><span class="title">by Exacto</span></p>
										</div>
										<div class="col text-end">
											<p><span class="title">Current price</span></p>
											<p>
												<span class="price">
													<img src="assets/images/icons/tether-usdt-logo.svg" alt="coin-ic"/>	
													100.00 EXS
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default SearchNFT
