import Web3 from "web3"


const utils = {
    toWei(value) {
        return Web3.utils.toWei(value, 'ether')
    },
    fromWei(value) {
        return Web3.utils.fromWei(value, 'ether')
    },
    toBnSum(value, ope) {
        return Web3.utils.toBN(value).add(ope).toString()
    },
    toBnSub(value, ope) {
        return Web3.utils.toBN(value).sub(ope).toString()
    },
    web3(provider) {
        const web3 = new Web3(provider)
        return web3
    },
    toChecksumAddress(address) {
        return Web3.utils.toChecksumAddress(address)
    },
    toBn(value) {
        return Web3.utils.toBN(value)
    },
    isAddress(address) {
        return Web3.utils.isAddress(address)
    },
    isChecksum(address) {
        return Web3.utils.checkAddressChecksum(address)
    },
    stringToBytes(value) {
        return Web3.utils.stringToHex(value)
    },
    isSameAddress(address1, address2) {
        if (!this.isChecksum(address1)) {
            address1 = this.toChecksumAddress(address1)
        }
        if (!this.isChecksum(address2)) {
            address2 = this.toChecksumAddress(address2)
        }
        return address1 == address2
    },
    fromBytes32(value) {
        return Web3.utils.hexToUtf8(value)
    },
    findItemJsonArray(x, y) {
        for (var key in x) {
            var index = x[key].findIndex(item => item.tokenId === Number(y))
            if (index >= 0) {
                return {
                    index: index,
                    sport: key
                }
            }
        }
        return null
    },
    findItemObject(x, y) {
        var index = x.findIndex(item => item.tokenId === Number(y))
        if (index >= 0) {
            return x[index]
        }
    },
    async saveInStorage(cards, force) {
        let lastUpdate = new Date(localStorage.getItem('lastUpdate'))
        let lengthCards = Number(localStorage.getItem('lengthCards'))
        if (lastUpdate !== null) {
            let dateNow = new Date()
            if (cards.length > lengthCards || lastUpdate < dateNow.setDate(dateNow.getDate() + 1) || force) {
                localStorage.setItem('StoredCards', JSON.stringify(cards))
                localStorage.setItem('lastUpdate', JSON.stringify(Date.now()))
                localStorage.setItem('lengthCards', JSON.stringify(cards.length))
            }
        } else {
            localStorage.setItem('StoredCards', JSON.stringify(cards))
            localStorage.setItem('lastUpdate', JSON.stringify(Date.now()))
            localStorage.setItem('lengthCards', JSON.stringify(cards.length))
        }
    },
}

export default utils