export const commonFunction = {
 ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true
  }
  else{
    return false
  }
}, onlySpaces(str) {
  return /^\s*$/.test(str);
},
checkPassword(str) {
  return /^\s*$/.test(str);
}
}