import React from 'react'
import {Link} from 'react-router-dom';
function Banner() {
    return (
        <section class="main-banner">
            <div class="overlay">
                <div class="container-fluid px-0">
                <div class="row">
					<div id = "ex-main-carousel" class="carousel slide" data-bs-ride = "carousel" data-bs-interval = "false">
						<div class="carousel-inner">
							<div class="carousel-item active">
								<img src="assets/images/carousel-main/slide-1.png" alt="carousel-img"/>
								<div class="carousel-caption">
									<div class="caption-title">
										NFT platform
									</div>
									<h1>
										Digital <br/> Collectibles <br/> For Sports
									</h1>
									<Link to="/marketplace" class="btn btnm-primary">
										GO TO MARKETPLACE
									</Link>
								</div>
							</div>
							<div class="carousel-item">
								<img src="assets/images/carousel-main/slide-1.png" alt="carousel-img"/>
								<div class="carousel-caption">
									<div class="caption-title">
										NFT platform
									</div>
									<h1>
										Digital <br/> Collectibles <br/> For Sports II
									</h1>
									<Link to="/marketplace" class="btn btnm-primary">
										GO TO MARKETPLACE
									</Link>
								</div>
							</div>
							<div class="carousel-item">
								<img src="assets/images/carousel-main/slide-1.png" alt="carousel-img"/>
								<div class="carousel-caption">
									<div class="caption-title">
										NFT platform
									</div>
									<h1>
										Digital <br/> Collectibles <br/> For Sports III
									</h1>
									<Link to="/marketplace" class="btn btnm-primary">
										GO TO MARKETPLACE
									</Link>
								</div>
							</div>
						</div>

						<button class="carousel-control-prev carousel-btn" type="button" data-bs-target="#ex-main-carousel" data-bs-slide="prev">
							<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						</button>
						<button class="carousel-control-next carousel-btn" type="button" data-bs-target="#ex-main-carousel" data-bs-slide="next">
							<span class="carousel-control-next-icon" aria-hidden="true"></span>
						</button>
					</div>
				</div>
                </div>

            </div>
        </section>
    )
}

export default Banner
