const ChainData = {
    polygon: [{
        chainId: `${process.env.VUE_APP_CHAINID}`,
        rpcUrls: [`${process.env.VUE_APP_RPC}`],
        blockExplorerUrls: ["https://polygonscan.com"],
        chainName: "Polygon",
        nativeCurrency: {
            decimals: 18,
            name: 'MATIC',
            symbol: 'MATIC'
        }
    }],
}

const Assets = {
    polygon: {
        ERC20: [{
                name: "EXS",
                symbol: "EXS",
                address: "0x11928CFCbC0398b9206C6D57ff7D8201555D6773",
                decimals: 18,
                icon: "https://img.ex-sports.io/imgs/icons/exs.png",
            },
            {
                name: "USDT",
                symbol: "USDT",
                address: `${process.env.VUE_APP_USDT}`,
                decimals: 6,
                icon: "url",
            }
        ],
        ERC1155: [{
            name: "Ex-Sports",
            symbol: "ExSports",
            address: `${process.env.VUE_APP_ERC1155}`,
            icon: "url"
        }],
        ONION: [{
            name: "Onion Auctions",
            // address: `${process.env.VUE_APP_ONION}`,
            address:"0xf04834284bD0e9cE4a892c0bF825Ed06F010ec9D",
            symbol: "OAS",
            decimals: 1,
            icon: "url"
        }],
        explorer: "https://polygonscan.com",
        sockets: [`${process.env.VUE_APP_WSS}`]

    }
}

export { ChainData, Assets }